$white:       #ffffff !default;

$red:         #af1b1b !default;
$gray:        #333333 !default; // text
$gray-light:  #f5f5f5 !default;
$blue-lighter:#eef9fe !default;
$blue-light:  #dcf0fa !default;
$blue-medium: #8BC7EF !default;
$blue:        #31476e !default; // headline
$blue-dark:   #050c19 !default;

$primary:     $blue !default;
$secondary:   $gray !default;

$font-family-sans-serif:    "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg:     $white !default;
$body-color:  $primary !default;

$card-height: 100%;

@import "~bootstrap/scss/bootstrap";

//
//  Fonts
//
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/lato-v14-latin-100.eot");
  src: local("Lato Hairline"), local("Lato-Hairline"), url("/fonts/lato-v14-latin-100.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-100.woff2") format("woff2"), url("/fonts/lato-v14-latin-100.woff") format("woff"), url("/fonts/lato-v14-latin-100.ttf") format("truetype"), url("/fonts/lato-v14-latin-100.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/lato-v14-latin-300.eot");
  src: local("Lato Light"), local("Lato-Light"), url("/fonts/lato-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-300.woff2") format("woff2"), url("/fonts/lato-v14-latin-300.woff") format("woff"), url("/fonts/lato-v14-latin-300.ttf") format("truetype"), url("/fonts/lato-v14-latin-300.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/lato-v14-latin-300italic.eot");
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url("/fonts/lato-v14-latin-300italic.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-300italic.woff2") format("woff2"), url("/fonts/lato-v14-latin-300italic.woff") format("woff"), url("/fonts/lato-v14-latin-300italic.ttf") format("truetype"), url("/fonts/lato-v14-latin-300italic.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 100;
  src: url("/fonts/lato-v14-latin-100italic.eot");
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url("/fonts/lato-v14-latin-100italic.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-100italic.woff2") format("woff2"), url("/fonts/lato-v14-latin-100italic.woff") format("woff"), url("/fonts/lato-v14-latin-100italic.ttf") format("truetype"), url("/fonts/lato-v14-latin-100italic.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/lato-v14-latin-regular.eot");
  src: local("Lato Regular"), local("Lato-Regular"), url("/fonts/lato-v14-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-regular.woff2") format("woff2"), url("/fonts/lato-v14-latin-regular.woff") format("woff"), url("/fonts/lato-v14-latin-regular.ttf") format("truetype"), url("/fonts/lato-v14-latin-regular.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/lato-v14-latin-italic.eot");
  src: local("Lato Italic"), local("Lato-Italic"), url("/fonts/lato-v14-latin-italic.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-italic.woff2") format("woff2"), url("/fonts/lato-v14-latin-italic.woff") format("woff"), url("/fonts/lato-v14-latin-italic.ttf") format("truetype"), url("/fonts/lato-v14-latin-italic.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/lato-v14-latin-700italic.eot");
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url("/fonts/lato-v14-latin-700italic.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-700italic.woff2") format("woff2"), url("/fonts/lato-v14-latin-700italic.woff") format("woff"), url("/fonts/lato-v14-latin-700italic.ttf") format("truetype"), url("/fonts/lato-v14-latin-700italic.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/lato-v14-latin-700.eot");
  src: local("Lato Bold"), local("Lato-Bold"), url("/fonts/lato-v14-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-700.woff2") format("woff2"), url("/fonts/lato-v14-latin-700.woff") format("woff"), url("/fonts/lato-v14-latin-700.ttf") format("truetype"), url("/fonts/lato-v14-latin-700.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/lato-v14-latin-900italic.eot");
  src: local("Lato Black Italic"), local("Lato-BlackItalic"), url("/fonts/lato-v14-latin-900italic.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-900italic.woff2") format("woff2"), url("/fonts/lato-v14-latin-900italic.woff") format("woff"), url("/fonts/lato-v14-latin-900italic.ttf") format("truetype"), url("/fonts/lato-v14-latin-900italic.svg#Lato") format("svg");
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/lato-v14-latin-900.eot");
  src: local("Lato Black"), local("Lato-Black"), url("/fonts/lato-v14-latin-900.eot?#iefix") format("embedded-opentype"), url("/fonts/lato-v14-latin-900.woff2") format("woff2"), url("/fonts/lato-v14-latin-900.woff") format("woff"), url("/fonts/lato-v14-latin-900.ttf") format("truetype"), url("/fonts/lato-v14-latin-900.svg#Lato") format("svg");
}

//
// Elements
//

body {
  scroll-snap-type: y mandatory;
  color: $gray;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphenate-limit-chars: 10 4 4;
  -ms-hyphenate-limit-chars: 10 4 4;
  hyphenate-limit-chars: 10 4 4;
  -webkit-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-lines: 2;
  hyphenate-limit-lines: 2;
  letter-spacing: .005rem;

  -webkit-hyphens: none;
  -moz-hyphens:    none;
  -ms-hyphens:     none;
  hyphens:         none;
}

header {
  background:
    url(images/recardial_head.png)
    bottom center
    no-repeat;
  background-size: cover;
  color: $white;
  .navbar {
    padding: .5rem 1rem .5rem 0;
  }
  .navbar-dark .navbar-nav .active > .nav-link {
    color: $blue;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: $white;
  }
}

section {
  @include media-breakpoint-up(md) {
    // scroll-snap-align: start;
  }
  h2 {
    hyphens: none;
  }

  .v-centered {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  &.summary {
    background:
      url(images/recardial_waves.svg)
      center center
      repeat-x;
    background-size: cover;
    @include media-breakpoint-up(xl) {
      background-size: contain;
    }
  }
  &.features1 {
    background:
      url(images/recardial_content-Bild_breit_1.jpg)
      left center
      no-repeat;
    background-size: cover;
    background-color: $blue-light;
  }
  &.features2 {
  }
  &.features3 {
  }
  &.features3x {
    .mockup.child-image {
      left: 0;
    }
  }
  &.features4 {
  }
  &.features5 {
  }
  &.features6 {
  }
  &.features7 {
  }
  &.features8 {
  }
  &.testimonials {
  }
  &.cooperation {
  }
  &.downloads {
    background:
      url(images/recardial_download-app-Bild.jpg)
      center center
      no-repeat;
    background-size: cover;
    background-color: $blue-light;
  }
  ul {
    list-style: none;
    li {
      position: relative;
      margin: .5rem 0;
      &::before {
        position: absolute;
        content: "";
        background-image: url(icons/check-circle.svg);
        background-size: contain;
        width: 1.2rem;
        height: 1.2rem;
        top: .2rem;
        left: -2rem;
      }
    }
  }

  ul.deflist{
    list-style: disc;
    li {
      position: relative;
      margin: .5rem 0;
      &::before {
        position: absolute;
        content: "";
        background-image: none;
        background-size: contain;
        width: 1.2rem;
        height: 1.2rem;
        top: .2rem;
        left: -2rem;
      }
    }
  }
}

a.button,  a.button:hover {
  background-color: #0b74b0;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
}

a.button.button12pxRouned {border-radius: 12px;}

footer {
  background: $blue-dark;
  color: $white;
  .nav-link.text-light.active {
    color: $blue-medium !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: $blue;
}

//
// IDs'n'Classes
//

.bg-red {
  background-color: $red;
}
.bg-gray {
  background-color: $gray;
}
.bg-gray-light {
  background-color: $gray-light;
}
.bg-blue-lighter {
  background-color: $blue-lighter;
}
.bg-blue-light {
  background-color: $blue-light;
}
.bg-blue-medium {
  background-color: $blue-medium;
}
.bg-blue {
  background-color: $blue-dark;
}
.bg-blue-dark {
  background-color: $blue-dark;
}

.arrange-images {
  position: relative;
  .bg-offset {
    box-shadow: -30px 30px 0 rgba($gray-light, 1);
  }
  .mockup {
    width: 300px;
    &.child-image {
      position: absolute;
      top: 40px;
      left: 100px;
    }
  }
}

.lighterFont {
  font-weight: lighter;
}

.rund-icon {
  position: relative;
  width: 70px;
}

.feature-icon  {
  float: left;
  width: 100px;
  padding-right: 1rem;
  margin-bottom: 1rem;
  @include media-breakpoint-up(sm) {
    float: none;
    padding-right: none;
  }
  transition: all .4s;
  &:hover {
    transform: scale(1.2);
  }
}

.people-icon {
  position: relative;
  float: left;
  width: 70px;
  margin-right: 15px;
  ~ .card-text {
    clear: both;
  }
}

.stern-icon {
  width: 30px;
  float: left;
}

.store-icon {
  display: none;
  visibility: hidden;
  &.a {
    height: 90px;
    padding: 15px 0 15px 15px;
    @include media-breakpoint-up(lg) {
      padding: 15px 0;
    }
  }
  &.g {
    height: 90px;
  }
}

.share-icon {
  width: 50px;
}

.table31 {
  border: 1px solid #333333;
}

.table31 tr.header td {
  white-space: nowrap;
  font-weight: bold;
  padding: 5px 10px;
  vertical-align: top;
}

.table31 tr.mike td {
  padding: 5px 10px;
  vertical-align: top;
}
//
//  additional Mediaqueries
//

// ≥576px
@include media-breakpoint-up(sm) {
}
// ≥768px
@include media-breakpoint-up(md) {
}
// ≥992px
@include media-breakpoint-up(lg) {
}
// ≥1200px
@include media-breakpoint-up(xl) {
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-only(xl) {
}

// <576px
@include media-breakpoint-down(xs) {
}
// <768px
@include media-breakpoint-down(sm) {
}
// <992
@include media-breakpoint-down(md) {
}
// <1200
@include media-breakpoint-down(lg) {
}

@media print {
}
